import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  LINGINSTAGRAM,
  LINGOFFICIAL,
  LINGOPENCHAT,
} from "../Constant/constant";

export default function RenderSetOfButton() {
  const styles = {
    body1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      flex: 1,
      resizeMode: "contain",
    },
    bodyImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      flex: 1,
      maxWidth: "250px",
      resizeMode: "contain",
    },
  };

  return (
    <Grid container style={styles.body1} mb={3}>
      <Grid container xs={12} sm={12} md={4} p={2} height={180}>
        <Grid
          container
          justifyContent="center"
          border={2}
          borderRadius={8}
          borderColor={MainTheme.colors.background[700]}
          height={"100%"}
          alignItems={"center"}
          width={"100%"}
          onClick={() => window.open(LINGOFFICIAL, "_blank")}
          style={{ cursor: "pointer" }}
        >
          <LazyLoad>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbNailLineOfficial.png")}
              style={styles.bodyImage}
              loading="lazy"
            />
          </LazyLoad>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={6} md={4} p={2} height={180}>
        <Grid
          container
          justifyContent="center"
          border={2}
          borderRadius={8}
          borderColor={MainTheme.colors.background[700]}
          height={"100%"}
          alignItems={"center"}
          width={"100%"}
          onClick={() => window.open(LINGINSTAGRAM, "_blank")}
          style={{ cursor: "pointer" }}
        >
          <LazyLoad>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbNailInstagram.png")}
              style={styles.bodyImage}
              loading="lazy"
            />
          </LazyLoad>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={6} md={4} p={2} height={180}>
        <Grid
          container
          justifyContent="center"
          border={2}
          borderRadius={8}
          borderColor={MainTheme.colors.background[700]}
          height={"100%"}
          alignItems={"center"}
          width={"100%"}
          onClick={() => window.open(LINGOPENCHAT, "_blank")}
          style={{ cursor: "pointer" }}
        >
          <LazyLoad>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbNailLineSquare.png")}
              style={styles.bodyImage}
              loading="lazy"
            />
          </LazyLoad>
        </Grid>
      </Grid>
    </Grid>
  );
}
