import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  LINGINSTAGRAM,
  LINGOFFICIAL,
  LINGOPENCHAT,
} from "../Constant/constant";

export default function RenderListOfImages() {
  const styles = {
    thumbnail: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      resizeMode: "contain",
      borderRadius: "16px",
      width: "100%", // Ensures image fits the grid width
      height: "auto", // Maintains aspect ratio
      objectfit: "contain", // Ensures the image doesn't stretch
    },
    body2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      resizeMode: "contain",
      cursor: "pointer",
    },
  };

  const setOfImages = [
    {
      img: require("../Constant/img/thumbnail1.jpeg"),
      alt: "thumbnail1",
    },
    {
      img: require("../Constant/img/thumbnail2.jpeg"),
      alt: "thumbnail2",
    },
    {
      img: require("../Constant/img/thumbnail3.jpeg"),
      alt: "thumbnail3",
    },
    {
      img: require("../Constant/img/thumbnail4.jpeg"),
      alt: "thumbnail4",
    },
    {
      img: require("../Constant/img/thumbnail5.jpeg"),
      alt: "thumbnail5",
    },
    {
      img: require("../Constant/img/thumbnail6.jpeg"),
      alt: "thumbnail6",
    },
    {
      img: require("../Constant/img/thumbnail7.jpeg"),
      alt: "thumbnail7",
    },
    {
      img: require("../Constant/img/thumbnail8.jpeg"),
      alt: "thumbnail8",
    },
  ];

  return (
    <Grid
      container
      flexDirection={"row"}
      style={styles.body2}
      mb={1}
      xs={12}
      onClick={() => window.open(LINGOFFICIAL, "_blank")}
    >
      {setOfImages.map((image, index) => (
        <Grid justifyContent="center" xs={12} sm={6} md={3} p={1} key={index}>
          <LazyLoad>
            <img
              alt={image.alt}
              src={image.img}
              style={styles.thumbnail}
              loading="lazy"
            />
          </LazyLoad>
        </Grid>
      ))}
    </Grid>
  );
}
