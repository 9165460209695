import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  LINGINSTAGRAM,
  LINGOFFICIAL,
  LINGOPENCHAT,
} from "../Constant/constant";
import { grey, lightBlue } from "@mui/material/colors";

export default function RenderImageWithDetails() {
  const styles = {
    thumbnail: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      resizeMode: "contain",
      borderRadius: "16px",
      width: "100%", // Ensures image fits the grid width
      height: "auto", // Maintains aspect ratio
      objectfit: "contain", // Ensures the image doesn't stretch
    },
  };

  type BrandNameProp = {
    uuid: string;
    img: string;
    alt: string;
    condition: string;
    name: string;
    detail: string;
    accessory: string;
    price: string;
  };

  const setOfImages = [
    {
      uuid: "27c7e4ea-116b-4aa7-80d7-590922b301e4",
      img: require("../Constant/img/27c7e4ea-116b-4aa7-80d7-590922b301e4.jpeg"),
      condition: "Used Like new",
      name: "LV x YK Weekend Tote Multiple colors",
      detail: "ไมโครชิพ สวยมาก ตปท รีเซลแสนปลายแล้ว  ",
      accessory: "อปก กล่อง ถุงผ้า สาย แทคห้อย",
      price: "89,999.-",
    },
    {
      uuid: "b4c116b0-8e08-41bf-8f6f-d4025df93a36",
      img: require("../Constant/img/b4c116b0-8e08-41bf-8f6f-d4025df93a36.jpeg"),
      condition: "Used Like new",
      name: "LOEWE Gate Mini 2022",
      detail: "สี Tan สวยใหม่ไม่มีตำหนิ ใส่ Promax ได้",
      accessory: "อปก ถุงผ้า บุค แทค",
      price: "38,999.-",
    },
    {
      uuid: "c2f86954-4aa4-4980-860a-9b959577b190",
      img: require("../Constant/img/c2f86954-4aa4-4980-860a-9b959577b190.JPG"),
      condition: "Used Like new",
      name: "LV Denim Jacket size 48 ",
      detail: "อก 46“ ยาว 25” สวยใหม่มากๆ ช้อปราคาแสนกว่าบาท ขายถูกมาก",
      accessory: "อปก ไม้แขวน",
      price: "29,999.-",
    },
    {
      uuid: "a451ab07-6961-4672-91e0-eaa5e728949a",
      img: require("../Constant/img/a451ab07-6961-4672-91e0-eaa5e728949a.JPG"),
      condition: "New",
      name: "GUCCI MINI TOTE BAG WITH INTERLOCKING",
      detail: "ของใหม่ ไม่เคยใช้ ช้อป 60,500",
      accessory: "อปก กล่อง ถุง��้า สาย บุค แทค ริบบิ้น",
      price: "28,999.-",
    },
    {
      uuid: "4c422576-905a-421f-b89f-1757b57659f6",
      img: require("../Constant/img/4c422576-905a-421f-b89f-1757b57659f6.JPG"),
      condition: "Used",
      name: "RIMOWA HYBRID Check - In L ปี 2019",
      detail:
        'สภาพใช้งานทั่วไป ไม่มีแตกล้อลื่น มีรอยเปื้อนบ้าง ขนาด 30x20x10" ใครไม่ชอบรุ่น zip มาจัดใบนี้ครับ',
      accessory: "อปก -",
      price: "26,999.-",
    },

    {
      uuid: "c3d78af4-dd2a-418b-a4bc-8728c1efd5f1",
      img: require("../Constant/img/c3d78af4-dd2a-418b-a4bc-8728c1efd5f1.JPG"),
      condition: "Used Like new",
      name: "FENDI Mini Peekaboo 2017",
      detail: `สภาพสวยไม่มีตำหนิหนัก ขนาดน่ารักมากๆ Size 9"x7"x4.5"`,
      accessory: "อปก ถุง��้า สาย การ์ด ชุดกัน��น",
      price: "45,999.-",
    },
  ];

  return (
    <>
      {setOfImages.map((image, index) => (
        <Grid
          justifyContent="center"
          xs={12}
          sm={6}
          md={3}
          p={1}
          key={index}
          mb={2}
        >
          <LazyLoad>
            <Box
              sx={{
                flexGrow: 1,
                position: "relative",
                display: "inline-block",
                bgcolor: lightBlue[100],
                borderRadius: "8px",
                pb: 1,
              }}
            >
              <img
                id={image.uuid}
                src={image.img}
                loading="lazy"
                style={{
                  width: "100%", // Adjust to fit the container
                  height: "auto", // Maintain aspect ratio
                  borderRadius: "8px", // Example for rounded corners
                }}
              />
              <Box
                sx={{
                  position: "absolute", // Position overlay relative to the container
                  top: 25,
                  left: 25,
                  p: 1,
                  borderRadius: "8px", // Match the image border radius
                  bgcolor: "primary.main",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white", // Text color for contrast
                  fontWeight: "bold",
                  fontSize: "1rem",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                }}
              >
                {image.condition}
              </Box>

              <Box sx={{ pt: 1 }}>{image.name}</Box>
              <Box sx={{ pt: 1, px: 2, maxLines: 2 }}>{image.detail}</Box>
              <Box sx={{ pt: 1, px: 2 }}>{image.accessory}</Box>
              <Box sx={{ pt: 1, px: 2 }}>❌ ❌ Price: {image.price} ❌ ❌</Box>
            </Box>
          </LazyLoad>
        </Grid>
      ))}
    </>
  );
}
