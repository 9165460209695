import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  LINGINSTAGRAM,
  LINGOFFICIAL,
  LINGOPENCHAT,
} from "../Constant/constant";

export default function RenderHeader() {
  const styles = {
    logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      flex: 1,
      resizeMode: "contain",
      maxWidth: "100px",
    },
    headingLogo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[200],
      flex: 1,
      resizeMode: "contain",
      fontWeight: 300,
    },
    subtitleLogo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      flex: 1,
      resizeMode: "contain",
      fontWeight: 700,
    },
    description: {
      maxWidth: "400px",
      borderBottom: "1px solid",
      borderWidth: "1px",
      borderColor: MainTheme.colors.common[50],
    },
    description2: {
      fontWeight: 900,
      color: MainTheme.colors.primary[400],
    },
  };

  return (
    <>
      <Grid container direction="column" pb={3}>
        <Grid container justifyContent="center">
          <LazyLoad>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/logo_bn2much.png")}
              style={styles.logo}
              loading="lazy"
            />
          </LazyLoad>
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant="h5" gutterBottom style={styles.headingLogo}>
            bn2much
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        style={styles.description}
        pb={2}
        mb={3}
      >
        <Grid container justifyContent="center">
          <Typography variant="h5" gutterBottom style={styles.subtitleLogo}>
            รับซื้อ-ขาย-แลกเปลี่ยน
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant="h5" gutterBottom style={styles.subtitleLogo}>
            BRANDNAME แท้ 100%
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        style={styles.description2}
        pb={2}
        mb={3}
      >
        <Grid container justifyContent="center">
          <Typography
            variant="subtitle2"
            gutterBottom
            style={styles.description2}
          >
            BUY-SELL-TRADE AUTHENTIC BRANDNAME
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
