import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import LazyLoad from "react-lazyload";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  LINGINSTAGRAM,
  LINGOFFICIAL,
  LINGOPENCHAT,
} from "../Constant/constant";
import packageJson from "../../package.json";

export default function RenderFooter() {
  const styles = {
    body1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      flex: 1,
      resizeMode: "contain",
    },
    headingLogo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[200],
      flex: 1,
      resizeMode: "contain",
      fontWeight: 300,
    },
    bodyImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      flex: 1,
      maxWidth: "250px",
      resizeMode: "contain",
    },
    subContainer: { justifyContent: "center", alignContent: "center" },
    footer: {
      display: "flex",
      minHeight: "50px",
      justifyContent: "flex-end",
      alignItems: "center",
      color: MainTheme.colors.third[800],
    },
  };

  const currentYear = dayjs().year();

  React.useEffect(() => {
    console.log(`App Version: ${packageJson.version}`);
  }, []);

  return (
    <>
      <Grid container direction="column" style={styles.body1} mb={3}>
        <Grid container justifyContent="center" flexDirection={"column"}>
          <Typography variant="h5" gutterBottom style={styles.headingLogo}>
            Bn2much รับซื้อ-ขาย-แลกเปลี่ยน กระเป๋าแบรนด์เนม
          </Typography>
          <Typography variant="h5" gutterBottom style={styles.headingLogo}>
            กระเป๋าแบรนด์เนม มือสอง ของแท้
          </Typography>
          <Typography variant="h5" gutterBottom style={styles.headingLogo}>
            เชียงใหม่ รับซื้อถึงที่
          </Typography>
        </Grid>
        <Grid container justifyContent="center"></Grid>
        <Grid container justifyContent="center">
          <Link href={LINGOPENCHAT}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbNailLineSquare.png")}
              style={styles.bodyImage}
              loading="lazy"
            />
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={styles.subContainer}>
        <Grid xs={12} md={8} style={styles.footer}>
          © {currentYear}, Designed by Bn2much
        </Grid>
      </Grid>
    </>
  );
}
